
// see this blog post on submitting to google forms
// https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175
// note to self: for some reason I needed to host the script on my personal gmail account
// to find the script, go to script.google.com

/*
	Notes on deployment of forms script:
	To test, Publish > Deploy as web app > (right click, copy "latest code" link for a dev url)
	To update, Publish > Deploy as web app > project version > new
*/

const url = 'https://script.google.com/macros/s/AKfycbxbcc9t7kbzcw_DZ7VmU3VFgXsz4Cs_SnuyluDsAKdWyraFHCrP/exec'

export async function submitFormData(formName: string, data: Object): Promise<boolean> {
	data["form_name"] = formName;
	const combined_url = url + "?" + serialize(data);
	try {
		await fetch(combined_url, { mode: "no-cors", }); // no-cors is required
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

// https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
function serialize(obj: Object): string {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}