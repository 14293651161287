import React, { useRef, useState } from "react"

import lnr from "../static/vendors/linericon/style.module.css"
import { submitFormData } from "../../../services/forms"

export default ({reason}) => {
	const [state, setState] = useState({ submitted: "unsubmitted" });
	const emailInputRef = useRef(null);

	const submit = async (event) => {
		event.preventDefault();
		if (state.submitted !== "submitting") {
      setState({...state, submitted: "submitting"});
			if (emailInputRef.current) {
				let data = {
					email: emailInputRef.current.value,
					reason,
				};
				try {
					let success = await submitFormData("contactme", data);
					if (success) {
						setState({...state, submitted: "submitted"});
					}
				} catch(err) {
					console.error(err);
				}
			}
		}
	}

	// TODO consider using input onFocus and onBlur to remove placeholder
	return (
		<form className="contactme-form">
			<input type="hidden" name="reason" value={reason} />
			<input
				ref={emailInputRef}
				type="email"
				name="email"
				placeholder="Enter email address"
				style={{height:"40px", width:"200px", verticalAlign:"middle", border:"none", padding:"0 0 0 20px"}}
				required
			>
			</input>
			<button
				onClick={submit}
				className="btn sub-btn"
				style={{height:"40px", verticalAlign:"middle", background:"black", color:"white", borderRadius:"0px", margin:0}}
			>
				<span className={`${lnr.lnr} ${lnr.lnrArrowRight}`}></span>
			</button>
			{ state.submitted === "submitting" &&
        <p>submitting...</p>
      }
      {
        state.submitted === "submitted" &&
        <p>We'll be in touch shortly. Thanks!</p>
      }
		</form>
	);
}